<script>
import { computed, onMounted } from '@vue/composition-api'
import AppTitle from '@/layouts/variants/content/vertical-nav/AppTitle.vue'
import useJwt from '@/auth/jwt/useJwt'
import qs from 'qs'
import AppAvatar from '@/views/sso/AppAvatar.vue'
import { useRouter } from '@core/utils'

export default {
  name: 'ChoseSysNew',
  components: { AppTitle, AppAvatar },
  setup() {
    const ssoUser = localStorage.getItem('ssoUser') ? JSON.parse(localStorage.ssoUser) : {}
    const username = computed(() => ssoUser?.nickname || ssoUser?.username || '') // 昵称
    const avatar = computed(() => ssoUser?.avatar || '') // 头像
    const sysList = JSON.parse(localStorage.getItem('ssoAPP')) // 应用列表
    const { router } = useRouter()

    onMounted(() => {
      document.title = '小五云平台'
      if (JSON.stringify(ssoUser) === '{}') {
        router.push({ name: 'sso-login' })
      }
    })
    sysList.forEach(n => {
      // eslint-disable-next-line no-param-reassign
      n.roleList = []
      if (n.appUserRoles.length > 0) {
        n.appUserRoles.forEach(i => n.roleList.push(i.name))
      }
    })

    // logo 合集
    const imgMap = {
      'silverdawn-studio-d5d2n5jt': require('@/assets/images/pages/login/studio.png'),
      'silverdawn-staff-server': require('@/assets/images/pages/login/staff.png'),
      'silverdawn-operation-w7bc92tq': require('@/assets/images/pages/login/CMS.png'),
      'silverdawn-auth-yN7rR9': require('@/assets/images/pages/login/auth.png'),
      'silverdawn-recruit-t2oakcq3': require('@/assets/images/pages/login/recruit.png'),
      'silverdawn-website-server': require('@/assets/images/pages/login/market1.png'),
    }

    function logout() {
      localStorage.removeItem('ssoToken')
      localStorage.removeItem('ssoUser')
      localStorage.removeItem('ssoAPP')
      setTimeout(() => router.replace({ name: 'sso-login' }), 1000)
    }

    function logSystem(item) {
      const data = {
        id: item.id,
        redirect: item.serverUrl,
      }
      useJwt.ssoLogSys(qs.stringify(data)).then(response => {
        if (response.data.code === 200) {
          window.open(response.data.data, '_blank')
        } else {
          if (response.data.msg === '未登录') logout()
          this.errorMsg = response.data.msg
        }
      })
    }

    return {
      username,
      avatar,
      imgMap,
      sysList,

      logSystem,
    }
  },
}
</script>

<template>
  <div class="appList">
    <template v-if="sysList.length">
      <div
        v-for="item in sysList"
        :key="item.appId"
        class="apply"
      >
        <v-avatar
          height="200"
          tile
          width="300"
        >
          <v-img
            :src="imgMap[item.appId]"
            cover
          />
        </v-avatar>
        <div
          class="mask"
          @click="logSystem(item)"
        >
          <div class="mask_title">
            {{ item.name }}
          </div>
          <div class="mask_tip">
            {{ item.roleList.toString() }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="fs14 p30 w"
        style="width: 100%; text-align: center"
      >
        <img
          alt=""
          src="@/assets/images/login/empty.png"
          style="width: 100px"
        >
        <div>
          暂无授权应用，请联系管理员
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang='scss'>
.appList {
  width: 100%;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  .apply {
    width: 300px;
    height: 200px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .mask {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content:center;
      flex-direction: column;
      cursor: pointer;
      gap: 8px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #ffffff;
      background-color: #1f1d31c9;

      &_title {
        font-size: 20px;
        font-weight: bold;
      }

      &_tip {
        font-size: 12px;
      }
    }
  }
}
</style>
